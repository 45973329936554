/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function() {
  if (window.__twitterIntentHandler) { return; }
  var intentTwitterRegex = /twitter\.com\/intent\/(\w+)/,
      intentFacebookRegex = /facebook\.com\/sharer\/(\w+)/,
      intentPinterestRegex = /pinterest\.com\/pin\/(\w+)/,
      intentTumblrRegex = /tumblr\.com\/share\/(\w+)/,
      windowOptions = 'scrollbars=yes,resizable=yes,toolbar=no,location=yes',
      width = 550,
      height = 420,
      winHeight = screen.height,
      winWidth = screen.width;
 
  function handleIntent(e) {
    e = e || window.event;
    var target = e.target || e.srcElement,
        m, left, top;
 
    while (target && target.nodeName.toLowerCase() !== 'a') {
      target = target.parentNode;
    }
 
    if (target && target.nodeName.toLowerCase() === 'a' && target.href) {
      m = target.href.match(intentTwitterRegex);
      n = target.href.match(intentFacebookRegex);
      o = target.href.match(intentPinterestRegex);
      p = target.href.match(intentTumblrRegex);
      if (m || n || o || p) {
        left = Math.round((winWidth / 2) - (width / 2));
        top = 0;
 
        if (winHeight > height) {
          top = Math.round((winHeight / 2) - (height / 2));
        }
 
        window.open(target.href, 'intent', windowOptions + ',width=' + width +
                                           ',height=' + height + ',left=' + left + ',top=' + top);
        e.returnValue = false;
        e.preventDefault && e.preventDefault();
      }
    }
  }
 
  if (document.addEventListener) {
    document.addEventListener('click', handleIntent, false);
  } else if (document.attachEvent) {
    document.attachEvent('onclick', handleIntent);
  }
  window.__twitterIntentHandler = true;
}());



(function($) {
  "use strict";
	
	
	var initiable = true,
		  History;
		  
  var speed = 200;
 



 /*
  *  BrowserDetect
  *
  */
var BrowserDetect = {
	init: function () {
		this.browser = 	this.searchString(this.dataBrowser) ||
										"an unknown browser";
		this.version = 	this.searchVersion(navigator.userAgent) ||
										this.searchVersion(navigator.appVersion) ||
										"an unknown version";
		this.OS = this.searchString(this.dataOS) || "an unknown OS";
	},
	searchString: function (data) {
		for (var i=0;i<data.length;i++) {
			var dataString = data[i].string;
			var dataProp = data[i].prop;
			this.versionSearchString = data[i].versionSearch || data[i].identity;
			if (dataString) {
				if (dataString.indexOf(data[i].subString) !== -1) {
					return data[i].identity;
				}
			}
			else if (dataProp) {
				return data[i].identity;
			}
		}
	},
	searchVersion: function (dataString) {
		var index = dataString.indexOf(this.versionSearchString);
		if (index === -1) { return; }
		return parseFloat(dataString.substring(index+this.versionSearchString.length+1));
	},
	dataBrowser: [
		{
			string: navigator.userAgent,
			subString: "Chrome",
			identity: "Chrome"
		},
		{   string: navigator.userAgent,
			subString: "OmniWeb",
			versionSearch: "OmniWeb/",
			identity: "OmniWeb"
		},
		{
			string: navigator.vendor,
			subString: "Apple",
			identity: "Safari",
			versionSearch: "Version"
		},
		{
			prop: window.opera,
			identity: "Opera",
			versionSearch: "Version"
		},
		{
			string: navigator.vendor,
			subString: "iCab",
			identity: "iCab"
		},
		{
			string: navigator.vendor,
			subString: "KDE",
			identity: "Konqueror"
		},
		{
			string: navigator.userAgent,
			subString: "Firefox",
			identity: "Firefox"
		},
		{
			string: navigator.vendor,
			subString: "Camino",
			identity: "Camino"
		},
		{   // for newer Netscapes (6+)
			string: navigator.userAgent,
			subString: "Netscape",
			identity: "Netscape"
		},
		{
			string: navigator.userAgent,
			subString: "MSIE",
			identity: "Explorer",
			versionSearch: "MSIE"
		},
		{
			string: navigator.userAgent,
			subString: "Gecko",
			identity: "Mozilla",
			versionSearch: "rv"
		},
		{     // for older Netscapes (4-)
			string: navigator.userAgent,
			subString: "Mozilla",
			identity: "Netscape",
			versionSearch: "Mozilla"
		}
	],
	dataOS : [
		{
			string: navigator.platform,
			subString: "Win",
			identity: "Windows"
		},
		{
			string: navigator.platform,
			subString: "Mac",
			identity: "Mac"
		},
		{
			string: navigator.userAgent,
			subString: "iPhone",
			identity: "iPhone/iPod"
		},
		 {
			string: navigator.userAgent,
			subString: "iPad",
			identity: "iPad"
		},
		{
			string: navigator.platform,
			subString: "Linux",
			identity: "Linux"
		}
	]
}; // BrowserDetect
  

  
  
  

 /*
  *  add_marker
  *
  *  This function will add a marker to the selected Google Map
  *
  *  @type	function
  *  @date	8/11/2013
  *  @since	4.3.0
  *
  *  @param	$marker (jQuery element)
  *  @param	map (Google Map object)
  *  @return	n/a
  */
  
  var add_marker = function ( $marker, map ) {
  
  	// var
  	var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') ),
  	    market_image = {
          url: '/wp-content/themes/tfa-theme/dist/images/tfa-google-maps-marker.png',
          // This marker is 20 pixels wide by 32 pixels high.
          size: new google.maps.Size(112, 85),
          // The origin for this image is (0, 0).
          origin: new google.maps.Point(0, 0),
          // The anchor for this image is the base of the flagpole at (0, 32).
          anchor: new google.maps.Point(32, 85)
        };
  
  	// create marker
  	var marker = new google.maps.Marker({
  		position    : latlng,
  		icon        : market_image,
  		map         : map
  	});
  
  	// add to array
  	map.markers.push( marker );
  
  	// if marker contains HTML, add it to an infoWindow
  	if( $marker.html() )
  	{
  		// create info window
  		var infowindow = new google.maps.InfoWindow({
  			content		: $marker.html()
  		});
  
  		// show info window when marker is clicked
  		google.maps.event.addListener(marker, 'click', function() {
  
  			infowindow.open( map, marker );
  
  		});
  	}
  
  }; 
  
  
  
  /*
  *  center_map
  *
  *  This function will center the map, showing all markers attached to this map
  *
  *  @type	function
  *  @date	8/11/2013
  *  @since	4.3.0
  *
  *  @param	map (Google Map object)
  *  @return	n/a
  */
  
  var center_map = function ( map ) {
  
  	// vars
  	var bounds = new google.maps.LatLngBounds();
  
  	// loop through all markers and create bounds
  	$.each( map.markers, function( i, marker ){
  
  		var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
  
  		bounds.extend( latlng );
  
  	});
  
  	// only 1 marker?
  	if( map.markers.length === 1 )
  	{
  		// set center of map
  	    map.setCenter( bounds.getCenter() );
  	    map.setZoom( 16 );
  	}
  	else
  	{
  		// fit to bounds
  		map.fitBounds( bounds );
  	}
  
  };
  
  
  
  
  /*
  *  create_map
  *
  *  This function will render a Google Map onto the selected jQuery element
  *
  *  @type	function
  *  @date	8/11/2013
  *  @since	4.3.0
  *
  *  @param	$el (jQuery element)
  *  @return	n/a
  */
  
  var create_map = function ( $el ) {
  	
  	// var
  	var $markers = $el.find('.marker'),
  	    touchable = 'ontouchstart' in document.documentElement;
  	
  	
  	// vars
  	var args = {
  		zoom		        : 15,
  		draggable 			: !touchable,
  		scrollwheel     : false,
  		center		      : new google.maps.LatLng(0, 0),
  		mapTypeId       : google.maps.MapTypeId.ROADMAP,
  		mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: google.maps.ControlPosition.LEFT_BOTTOM
      }
  	};
  	
  	// create map	        	
  	var map = new google.maps.Map( $el[0], args);
  	
  	
  	// style map	
  	var styles = [
		  {
		    "stylers": [
		      { "visibility": "simplified" },
		      { "color": "#CCCCCC" }
		    ]
		  },{
		    "featureType": "water",
		    "stylers": [
		      { "visibility": "on" },
		      { "color": "#252525" }
		    ]
		  },{
		    "featureType": "road",
		    "elementType": "geometry",
		    "stylers": [
		      { "visibility": "simplified" },
		      { "color": "#FFFFFF" }
		    ]
		  },{
		    "featureType": "road.highway",
		    "elementType": "geometry",
		    "stylers": [
		      { "color": "#9E9E9E" }
		    ]
		  },{
		    "elementType": "labels.icon",
		    "stylers": [
		      { "visibility": "off" }
		    ]
		  },{
		    "featureType": "road.highway",
		    "elementType": "labels.text",
		    "stylers": [
		      { "visibility": "simplified" },
		      { "color": "#252525" }
		    ]
		  },{
		    "featureType": "road.arterial",
		    "elementType": "labels.text",
		    "stylers": [
		      { "visibility": "simplified" },
		      { "color": "#252525" }
		    ]
		  },{
		  }
		];
		var styledMap = new google.maps.StyledMapType(styles, {name: "TheMob"});
  	 
  	map.mapTypes.set('map_style', styledMap);
    map.setMapTypeId('map_style');
  	
  	// add a markers reference
  	map.markers = [];
  	
  	
  	// add markers
  	$markers.each(function(){
      add_marker( $(this), map );
  	});
  	
  	
  	// center map
  	center_map( map );
  	
  	
  	// return
  	return map;
  	  
  };
  
  
  
  /*
	*  preload
	*
	*  This function will preload page images.
	*
	*  @type	function
	*  @date	14/09/2015
	*  @since	1.0.0
	*
	*  @param	
	*  @return	n/a
	*/
	
	var preload_imgs = function() {
		
		var loader = new PxLoader();
	
		var $images = $('img');
		$images.each(function( index, element ) {
			var pxImage = new PxLoaderImage( $(element).attr('src') ); 
			loader.add(pxImage); 
		});
		
		// callback that runs every time an image loads 
		loader.addProgressListener(function(e) {
		    // the event provides stats on the number of completed items 		    
		    var percentage = e.completedCount / e.totalCount;
		}); 
		
		// callback
		loader.addCompletionListener(function() { 
  		
		}); 
		
		loader.start();
		
	};
	
	
	
 /*
	*  triggerClick
	*
	*  @type	function
	*  @date	13/08/2015
	*  @since	1.0.0
	*
	*  @param	ev (jQuery object)
	*  @return	n/a
	*/
	
	var triggerClick = function (e) {
  	
  	if (!e.metaKey && !e.ctrlKey && e.button !== 1 && e.button !== 2) {
		
  		e.preventDefault();
      e.stopPropagation();
    		
      var _title = $(this).data("title"),
      		_href = $(this).attr("href");
  		
  		var $body = $('body');
  		
  		if( $body.hasClass('nav-open') ) {
    		var $items = $('.menu-item'),
            $wrapper = $('#fullscreen-menu');
    		$body.removeClass( 'nav-open nav-open-animation' );
    		setTimeout(function(){
          $wrapper.fadeTo(speed, 0, function() {
            $wrapper.removeClass('shown');
            $items.addClass('init');
          } ); 
        }, 300);
  		}
  
  		if( window.location.href === _href ){
    		// same page
  		}
  		else {
  			// change the url without a page refresh and add a history entry.
  			History.pushState(null, _title, _href);
  		}
		
		}
		
	};
	
	
	
 /*
	*  loadContent
	*
	*  @type	function
	*  @date	13/08/2015
	*  @since	1.0.0
	*
	*  @param	ev (jQuery object)
	*  @return	n/a
	*/
	
	var loadContent = function (href) {
  	    
		function doAjaxLoad(){
  		   
			$("html, body").animate({ scrollTop: 0 }, "fast");
      
      var $loader = $('.loader').removeClass('loader-shove');
	
		  var deferred = new $.Deferred();
      setTimeout(function(){
			  deferred.resolve(); 
		  }, 2000);
	
			$.ajax({
				url: href,
				type: 'POST',
				dataType: 'html',
				success: function( data ) {
  				
  				$("html, body").animate({scrollTop:0}, 0);
  				
  				var $data = $(data),
  				    body = $data.filter('#body-content'),
  				    classes = body.attr('class'),
  				    content = body.html();	

					$('#body-content').attr('class', classes).html(content);
					
					$.when(deferred).done(function() {
           				 $loader.addClass('loader-shove'); 
         			});
					
					
					UTIL.loadEvents();
					
	   				 
				},
				error: function (xhr, ajaxOptions, thrownError) {
			    if (xhr.status === 404) { // Not found
						var $data = $(xhr.responseText);
						$("html, body").animate({scrollTop:0}, 0);
					
            var content = $data.find('.body').html();
            $('.body').html(content);
						
						$loader.addClass('loader-shove');
						
						UTIL.loadEvents();
					}
				} 
			});	
			
			
		} // doAjaxLoad
		
	
		doAjaxLoad();
		
	
	};
	
	
 /*
  *  init
  *
  *  This function will init application
  *
  *  @type	function
  *  @date	30/03/20016
  *  @since	1.0.0
  *
  *  @param	$el (jQuery element)
  *  @return	n/a
  */
  
  var init = function () {
    
		preload_imgs();
		
		// Menu button behavior
    var $items = $('.menu-item').addClass('init');
    $('.navbar-toggle').on('click', function(e) {
      
      e.preventDefault();
      e.stopPropagation();
      
      var $body = $('body'),
          $wrapper = $('#fullscreen-menu');
      
      if( $body.hasClass('nav-open') ){
        // close menu
        $body.removeClass( 'nav-open nav-open-animation' );
        $wrapper.fadeTo(speed, 0, function() {
          $wrapper.removeClass('shown');
          $items.addClass('init');
        } ); 
      }
      else {
        // open menu
        $('html,body').animate({ scrollTop: 0 }, 75, function() {
          
          $items.each(function(index, elem) { 
            var $elem = $(elem);                
            setTimeout(function() {
              $elem.removeClass('init');
            }, speed/6 * index);
          });
          $body.addClass( 'nav-open-animation' );
          $wrapper.addClass('shown').fadeTo(speed, 1, function() {
            $body.addClass( 'nav-open' );
          } );
          
        });
      }
    });
    // ----- -----
    
    $(".language-selector a, .link-panel, .brand, .nav a, .ajax-link, .go-back, article.logo a").on("click", triggerClick);
		
		if (initiable) {
  		
  		
  		BrowserDetect.init();
  		
			var $window = $(window),
					$html = $('html'),
					browser = BrowserDetect.browser.toLowerCase(),
					version = BrowserDetect.version;
			
			$html.addClass(browser);
			$html.addClass(browser + version);
  		
  		
  		
      History = window.History;
      
      if (History.enabled) {
        
	      // history is supported; do magical things				
				History.Adapter.bind(window,'statechange',function(){ 
					var State = History.getState();
										
					// ga('send', 'pageview', State.url);
					// _gaq.push(['_trackPageview', State.url.replace(/^.*\/\/[^\/]+/, '')]);
				  var $body_content = $('#body-content');
				  		
					var matches;
          if ($body_content.is('.page-template-services, .single-service') && (matches = State.url.match(/\/(services|servicios)\/(.*)$/))) {
            // Services page
            var service = matches[2];
            if (!service) {
              // load the services page content
              loadContent(State.url);
            }
          }
          
          else if ($body_content.is('.page-template-innovation, .single-innovation') && (matches = State.url.match(/\/(innovation|innovacion)\/(.*)$/))) {
            // Innovation page
            var innovation = matches[2];
            if (!innovation) {
              // load the innovation page content
              loadContent(State.url);
            }
          }
          
          else {
            // load the content
            loadContent(State.url);
          }

				});
					
			} else {
			    // history is not supported; nothing fancy here
			}
	
			initiable = false;
    }

  };
  
  
  
  
 /*
  *  adjust_imgs
  *
  *  This function will init application
  *
  *  @type	function
  *  @date	30/03/20016
  *  @since	1.0.0
  *
  *  @param	$el (jQuery element)
  *  @return	n/a
  */
  
  var adjust_imgs = function($imgs, $wrapper) {
    
    var wrapper_h = $wrapper.height(),
        wrapper_w = $wrapper.width();
    
    $imgs.each(function(index, elem) {

      var $img = $(elem),
          img_h = $img.attr('height'),
          img_w = $img.attr('width');

      var wrapper = wrapper_w / wrapper_h,
          image = img_w / img_h;
      
      var width, height,
          margin_left, margin_top;
          
      if (wrapper < image) { 
				height = wrapper_h;
  			width = (img_w * wrapper_h / img_h);
  			margin_top = 0;
  			margin_left = (wrapper_w - width) / 2;
  			margin_left = (margin_left > 0) ? (-1)*margin_left : margin_left;
			}
			else {
				width = wrapper_w;
  			height = (wrapper_w * img_h / img_w);
  			margin_top = (wrapper_h - height) / 2;
  			margin_top = (margin_top > 0) ? (-1)*margin_top : margin_top;
  			margin_left = 0;
			}
      
      $img.css({width: width, height: height, marginTop: margin_top, marginLeft: margin_left});
    });				
				
  };
  
  
  
  
 /*
  *  adjust_imgs
  *
  *  This function will init application
  *
  *  @type	function
  *  @date	30/03/20016
  *  @since	1.0.0
  *
  *  @param	$el (jQuery element)
  *  @return	n/a
  */  
  
  var resize_grid = function($grid) {
     
    var $articles = $('.item article', $grid);
    
    $articles.each(function(index, elem) {
      var $img = $('header img', elem);
      
      var img_h = $img.attr('height'),
          img_w = $img.attr('width');
      
      var $wrapper = $(elem),
          wrapper_h = $wrapper.height(),
          wrapper_w = $wrapper.width();
      
      var wrapper = wrapper_w / wrapper_h, 
          image = img_w / img_h;
      
      var width, height,
          margin_left, margin_top;
          
      if (wrapper < image) { 
				height = wrapper_h;
  			width = (img_w * wrapper_h / img_h);
  			margin_top = 0;
  			margin_left = (wrapper_w - width) / 2;
  			margin_left = (margin_left > 0) ? (-1)*margin_left : margin_left;
			}
			else {
				width = wrapper_w;
  			height = (wrapper_w * img_h / img_w);
  			margin_top = (wrapper_h - height) / 2;
  			margin_top = (margin_top > 0) ? (-1)*margin_top : margin_top;
  			margin_left = 0;
			}
      
      $img.css({width: width, height: height, marginTop: margin_top, marginLeft: margin_left});
    });				
				
  };
  
  
  

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        
        init();
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        
        // Promo resize
        
        var $article_base = $('article.hentry img').eq(0);
        $article_base.imagesLoaded( function(){
          $(window).on('resize', function() {
            // TODO: modernizr mq max sm
            $('article.promo, article.promo .span').height($article_base.height()); 
          }).trigger('resize');
        });
        // ----- -----
        
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'error404': {
      init: function() {
        
        var $window = $(window);
        $window.on('resize', function() {
          var mquery = Modernizr.mq('(min-width: 768px)');
          
          if (mquery && $('#body-content').hasClass('error404')) { 
            
            var window_h = $window.height(),
                header_h = $('.desktop-header').height();

            var height = window_h - header_h + 75;
            
            $('.main', '.wrap').height(height);
          }
        }).trigger('resize');
        
      }
    },
    'blog': {
      init: function() {
        
        /* FILTERS - work */
        jQuery.get('//cdnjs.cloudflare.com/ajax/libs/jquery.isotope/2.2.2/isotope.pkgd.min.js', function(data) { eval(data); }); 
        
        var $grid = $('.blog.wrap .content');
        $grid.imagesLoaded( function(){
          $grid.isotope({
            itemSelector: '.post',
            layoutMode: 'fitRows'
          });
		  
		  
		  $('.filter-list').on('click', '.filter', function(e) {
            $('.filter').removeClass('all');
            var filterValue = $( this ).addClass('all').attr('data-filter');
           	if(filterValue!=='*'){
            	window.location.hash = filterValue.substring(1);
			}else{
				 window.history.pushState("", document.title, window.location.pathname);
			}
            $grid.isotope({ filter: filterValue });
          });
          
          if( window.location.hash ) {
            var hashtag = window.location.hash,
                selector = hashtag.replace('#', '.');
            
            $grid.isotope({ filter: selector }); 
            
            $('.filter').removeClass('all');
            $('.filter[data-filter="' + selector + '"]' ).addClass('all');
          }
		  
		  
		  
    
		  
        });
        
        /*$('.filter-list').on('click', '.filter', function(e) {
          $('.filter').removeClass('all');
          var filterValue = $( this ).addClass('all').attr('data-filter');
          $grid.isotope({ filter: filterValue });
        });*/
		
        var $panel = $('.link-panel'),
            $wrapper = $('.hentry .link-panel');    
        $(window).on('resize', function() { 
          $wrapper.each(function(index, elem) {
            adjust_imgs( $('img', $(this)), $(this)); 
          });          
        });
        
      }
    },
    'single_post': {
      init: function() {       
        $('.entry-content p').fitVids();
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() { 
        // JavaScript to be fired on the about us page
      }
    },
    'page_template_work': {
      init: function() {
        
        /* FILTERS - work */
        jQuery.get('//cdnjs.cloudflare.com/ajax/libs/jquery.isotope/2.2.2/isotope.pkgd.min.js', function(data) { eval(data); }); 
        
        var $grid = $('.grid');
        $grid.imagesLoaded( function(){
          $grid.isotope({
            itemSelector: '.item',
            layoutMode: 'fitRows'
          });
		  
		  
		  $('.filter-list').on('click', '.filter', function(e) {
            $('.filter').removeClass('all');
            var filterValue = $( this ).addClass('all').attr('data-filter');
			if(filterValue!=='*'){
            	window.location.hash = filterValue.substring(1);
			}else{
				 window.history.pushState("", document.title, window.location.pathname);
			}
            $grid.isotope({ filter: filterValue });
          });
          
          if( window.location.hash ) {
            var hashtag = window.location.hash,
                selector = hashtag.replace('#', '.');
            
            $grid.isotope({ filter: selector }); 
            
            $('.filter').removeClass('all');
            $('.filter[data-filter="' + selector + '"]' ).addClass('all');
          }
		  
		  
		  
        });
        
        /*$('.filter-list').on('click', '.filter', function(e) {
          $('.filter').removeClass('all');
          var filterValue = $( this ).addClass('all').attr('data-filter');
          $grid.isotope({ filter: filterValue });
        });*/
        
      }
    },
    'single_work': {
      init: function() {
        
        // jQuery.getScript('//cdnjs.cloudflare.com/ajax/libs/fancybox/2.1.5/jquery.fancybox.min.js', function(data, textStatus, jqxhr){}); 
        
        $(".gallery-item").fancybox({
          'padding'          : 0,
          'type'             : 'image',
          'openEffect'       : 'fade',
          'closeEffect'      : 'fade',
          'nextEffect'       : 'fade',
          'prevEffect'       : 'fade',
		  'autoCenter'  	: 'true',  
           'scrolling' 		: 'no',
      		'transitionIn'     : 'none',
      		'transitionOut'		 : 'none',
      		'tpl'              : {
        		'closeBtn'       : '<a title="Close" class="fancybox-item fancybox-close icon-tfa-close" href="javascript:;"></a>',
          	'next'           : '<a title="Next" class="fancybox-nav fancybox-next" href="javascript:;"><span class="icon-tfa-next"></span></a>',
          	'prev'           : '<a title="Previous" class="fancybox-nav fancybox-prev" href="javascript:;"><span class="icon-tfa-prev"></span></a>'
      		}
      	});
        	
        /* FITVIDS - youtube responsive */
        $('.media-wrapper.video').fitVids();
        
       var $header = $('main header');
        $(window).on('resize', function() {
          adjust_imgs($('img', $header), $header);
        }).trigger('resize');
		
        /*
        $header.imagesLoaded( function(){
          $(window).on('resize', function() {
            adjust_imgs($('img', $header), $header);
          }).trigger('resize');
          adjust_imgs($('img', $header), $header);
        });
        */
        
      }
    },
    'page_template_clients': {
      init: function() {
        
        /* FILTERS - client */
        jQuery.get('//cdnjs.cloudflare.com/ajax/libs/jquery.isotope/2.2.2/isotope.pkgd.min.js', function(data) { eval(data); }); 
        
        var $grid = $('.logo-grid');
        $grid.imagesLoaded( function(){
          $grid.isotope({
            itemSelector: '.logo',
            layoutMode: 'fitRows',
            hiddenStyle: {
              opacity: 0
    			  },
            visibleStyle: {
              opacity: 0.5
    			  }
          });
          
          $('.filter-list').on('click', '.filter', function(e) {
            $('.filter').removeClass('all');
            var filterValue = $( this ).addClass('all').attr('data-filter');
			if(filterValue!=='*'){
            	window.location.hash = filterValue.substring(1);
			}else{
				 window.history.pushState("", document.title, window.location.pathname);
			}
            $grid.isotope({ filter: filterValue });
          });
          
          if( window.location.hash ) {
            var hashtag = window.location.hash,
                selector = hashtag.replace('#', '.');
            
            $grid.isotope({ filter: selector }); 
            
            $('.filter').removeClass('all');
            $('.filter[data-filter="' + selector + '"]' ).addClass('all');
          }
          
        });
        
      }
    },
    'page_template_contact': {
      init: function() {
        
        // create map
        var map = null;
        $('.acf-map').each(function(){
      		map = create_map( $(this) );
      	});
      	// ----- -----
      	
        jQuery.get('/wp-content/plugins/contact-form-7/includes/js/jquery.form.min.js', function(data) { eval(data); });
				jQuery.get('/wp-content/plugins/contact-form-7/includes/js/scripts.js', function(data) { 
          var _wpcf7 = {"loaderUrl":"http:\/\/thefilmagency.binalogue.net\/wp-content\/plugins\/contact-form-7\/images\/ajax-loader.gif","recaptchaEmpty":"Please verify that you are not a robot.","sending":"Sending ...","cached":"1"};
          eval(data);
        }); 
      	
      	// Quiz input placeholder configuration
      	$('#quiz').attr('placeholder', $('.wpcf7-quiz-label').text());
      	// ----- -----
      	
      }  
    },
    'page_template_services': {
      init: function() {
        
        // when collapse is opening
        $('#accordion').on('show.bs.collapse', function (e) {          
          var $elem = $(e.target);
          var _title = $elem.data('title'),
              _href = $elem.data('slug'),
          	  idName = $elem.siblings().attr('id');

          History.pushState(null, _title, _href);
		  
          $("html, body").animate({ scrollTop: $("#" + idName).offset().top });
        });
		
      	
      }  
    },
    'single_service': {
      init: function() {
        
        // when collapse is opening
        $('#accordion').on('show.bs.collapse', function (e) {          
          var $elem = $(e.target);
          var _title = $elem.data('title'),
              _href = $elem.data('slug'),
              idName = $elem.siblings().attr('id');

          var path = ($('#body-content').hasClass('lang-en')) ? '/services/' : '/servicios/' ;
          
          History.pushState(null, _title, path + _href + '/'); 
		  
          $("html, body").animate({ scrollTop: $("#" + idName).offset().top });
        });
		
		
        var href = window.location.pathname,
        parts = href.split('/'),
        last = parts.pop();
        
        var selector = (last === '') ? parts[parts.length - 1] : last ;
        $('#collapse-' + selector).collapse('show');
              	
      } 
    },
    'page_template_innovation': {
      init: function() {
        
        // when collapse is opening
        $('#accordion').on('show.bs.collapse', function (e) {          
          var $elem = $(e.target);
          var _title = $elem.data('title'),
              _href = $elem.data('slug'),
              idName = $elem.siblings().attr('id');
          
          History.pushState(null, _title, _href);
		  
          $("html, body").animate({ scrollTop: $("#" + idName).offset().top });
        });
      	
      }  
    },
    'single_innovation': {
      init: function() {
		  
        // when collapse is opening
        $('#accordion').on('show.bs.collapse', function (e) {          
          var $elem = $(e.target);
          var _title = $elem.data('title'),
              _href = $elem.data('slug'),
              idName=$elem.siblings().attr('id');
          
          var path = ($('#body-content').hasClass('lang-en')) ? '/innovation/' : '/innovacion/' ;
          
          History.pushState(null, _title, path + _href + '/');
		  
          $("html, body").animate({ scrollTop: $("#" + idName).offset().top });
        });
        
        var href = window.location.pathname,
            parts = href.split('/'),
            last = parts.pop();
        
        var selector = (last === '') ? parts[parts.length - 1] : last ;
        $('#collapse-' + selector).collapse('show');

      }
    }
  };


  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.getElementById("body-content").className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
